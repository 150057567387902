import { Auth } from "aws-amplify";
import { loginActions } from "./";

export const signup = (name, phoneNumber, email, password, captchToken) => async dispatch => {
  //Invite Flow Flag determines whether Registration is needed or not
  const is_first_time_login = "0";
  await Auth.signUp({ username: email, password, attributes: { email, phone_number: phoneNumber, given_name: name, "custom:is_first_time_login": is_first_time_login} });//,captchToken
  return dispatch(loginActions.setUserEmail(email));
};

export const resendOTP = email => async () => {
  return await Auth.resendSignUp(email);
};

export const signupConfirm = (email, otp) => async () => {
  return await Auth.confirmSignUp(email, otp);
};
