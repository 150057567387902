import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import Modal from "@material-ui/core/Modal";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { useStyles } from "./styles";

export const Loader = ({ isLoading, title, content,actions,loader,className="model-body",disableBackdropClick=true,onClose,onClick }) => {
  const classes = useStyles();

  return (
    <Modal disableBackdropClick={disableBackdropClick} open={isLoading} onClose={onClose} onClick={onClick}>
      <Card className={`${classes.card} ${className}`} onClick={onClick}>
        {title && <><CardHeader title={<h2>{title}</h2>} />
        <Divider /></>}
        <div className={`${classes.circularProgressContainer} spinner`}>
          {loader?<img src={loader}/>:<CircularProgress className={classes.circularProgress} />}
        </div>
        <CardContent className="model-content">
          <Typography variant="body2" component="p">
            {content}
          </Typography>
          {actions && <><Divider /><div className="actions"> {actions}</div></>}
        </CardContent>
      </Card>
    </Modal>
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.object,
};

export default Loader;
