import React, {useState}  from "react";
import { useSelector } from "react-redux";
import AppHeader from "../Header/AppHeader";
import LeftMenu from '../LeftMenu'
import {useStyles} from "./styles";
import {PermissionContext} from "../PermissionContext";

import Announcement from "../../components/Header/Announcement";

import Grid from '@material-ui/core/Grid';
const stateSelector = state => ({
  selectedClinicId: state.clinicReducer.selectedClinicId,
  userRole: state.userReducer.userRole,
  userPermissionsBasedOnClinic: state.userReducer.userPermissionsBasedOnClinic,
});

const LeftMenuAndFooter = (props) => {
  const { selectedClinicId, userPermissionsBasedOnClinic, userRole } = useSelector(state => stateSelector(state));
  var permissions,privileges = [];
  if (userRole[selectedClinicId] !== undefined && userPermissionsBasedOnClinic[selectedClinicId] !== undefined) {
    userRole[selectedClinicId].forEach(role => {
      permissions = userPermissionsBasedOnClinic[selectedClinicId][role] || {};
      privileges = [...privileges,...(permissions["READ"] || []),...(permissions["WRITE"] || [])];
    });
  }
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState(true);

  const handleIconClick = () => {
    setIsOpen(!isOpen);
  }

  const handleClick = () => {
    setOpen(!open);
  };


  let wrapperClassName = `page-content-ctr ${classes.AdminWraper} ${!isOpen?classes.activeClass:''}`;

  return (
    <>
    <PermissionContext.Provider value={privileges}>
      <AppHeader
        handleIconClick={handleIconClick}
        isTopMenuFullWidth={isOpen}
      />
      <Announcement />
      <Grid className={wrapperClassName} >
        {props.showLeftMenu && <LeftMenu
          handleClick={handleClick}
          open={open}
          isLeftFullwidth={!isOpen}
        />}
        <div className={`${classes.AdminUser} ${props.contentClass||''} `}>
          <div className={classes.dashboardsMainParent}>
            {props.children}
          </div>
      </div>
    </Grid>   
    </PermissionContext.Provider>
    </>
  );
}

export default LeftMenuAndFooter
